<template>
  <div class="shipment-detail-status-map">
    <l-map
      ref="map"
      :zoom="mapData.zoom"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="mapData.options"
      :center="mapData.center"
      class="leaflet-map"
    >
      <l-control-zoom :position="mapData.controlZoomPosition" />
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />
      <l-feature-group
        ref="markers"
      >
        <l-marker
          v-if="getCurrentShipment"
          ref="pickup"
          :lat-lng="location('pickup')"
          :icon="getPickupIcon"
          data-test="pickup"
        >
          <l-popup
            data-test="pickup-popup"
            :content="formatPopup('pickup')"
            :options="popupOptions"
          />
        </l-marker>
        <l-marker
          v-if="getCurrentShipment"
          ref="delivery"
          :lat-lng="location('delivery')"
          :icon="getDeliveryIcon"
          data-test="delivery"
        >
          <l-popup
            data-test="delivery-popup"
            :content="formatPopup('delivery')"
            :options="popupOptions"
          />
        </l-marker>
      </l-feature-group>

      <l-polyline
        v-if="itinerary && itineraryVisible"
        ref="itinerary"
        :visible="itineraryOptions.visible"
        :lat-lngs="itinerary"
        :weight="itineraryOptions.style.weight"
        :color="itineraryOptions.style.color"
        :opacity="itineraryOptions.style.opacity"
        :fill="false"
      />

      <div
        v-if="hasDistance"
        class="tw-flex shipment-detail-status-map__distance__container tw-w-full"
      >
        <div class="shipment-detail-status-map__distance tw-rounded">
          {{ $t('shipment.labels.distance') }} <span class="tw-font-medium">{{ $n(distance) }}&nbsp;km</span>
        </div>
      </div>
    </l-map>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {
    LMap,
    LPolyline,
    LPopup,
    LMarker,
    LTileLayer,
    LFeatureGroup,
    LControlZoom
  } from 'vue2-leaflet'
  import { defineComponent } from '@vue/composition-api'

  import { icons } from '@/services/Leaflet'
  import { Shipment } from '@/resources'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - shipmentDetailStatusMap
   */
  export default defineComponent({
    name: 'ShipmentDetailStatusMap',
    components: {
      LMap,
      LPolyline,
      LPopup,
      LMarker,
      LTileLayer,
      LFeatureGroup,
      LControlZoom
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if there is a mission
       * @function hasMission
       */
      hasMission () {
        return !!this.getCurrentShipment.mission
      },
      /**
       * Returns the flyTo & flyToBounds options.
       * @function flyOptions
       */
      flyOptions () {
        return {
          animate: true,
          padding: [64, 64],
          duration: 0.8
        }
      },
      /**
       * Returns the distance in km
       * @function distance
       * @returns {number|string}
       */
      distance () {
        return (this.getCurrentShipment.travel.distance / 1000).toFixed(0)
      },
      /**
       * @function location
       * @returns {object}
       */
      location () {
        return (/** @type {string} */ direction) => this.hasMission
          ? this.getCurrentShipment.mission[direction].address.location
          : this.getCurrentShipment[direction].address.location
      },
      /**
       * @function isCancelled
       * @returns {boolean}
       */
      isCancelled () {
        const cancellationStates = ['cancelled', 'expired']
        return cancellationStates.includes(this.getCurrentShipment.state)
      },
      /**
       * Returns the appropriate marker icon according to the shipment & the pickup state.
       * @function getPickupIcon
       * @returns {any} icon
       */
      getPickupIcon () {
        const shipment = this.getCurrentShipment
        if (this.isCancelled) return icons.pickup('cancelled')
        if (shipment.state === 'planned') return icons.pickup('scheduled')

        return shipment.pickup.state === 'completed'
          ? icons.pickup('completed')
          : icons.pickup('in_progress')
      },
      /**
       * Returns the appropriate marker icon according to the shipment & the delivery state.
       * @function getDeliveryIcon
       * @returns {any} icon
       */
      getDeliveryIcon () {
        const shipment = this.getCurrentShipment
        if (this.isCancelled) return icons.delivery('cancelled')

        return shipment.delivery.state === 'completed'
          ? icons.delivery('completed')
          : icons.delivery('in_progress')
      }
    },
    data () {
      return {
        itineraryVisible: false,
        itinerary: null,
        hasDistance: false,
        canFlyToItinerary: false,
        itineraryOptions: {
          visible: true,
          style: {
            color: '#287696',
            opacity: 1,
            weight: 3
          }
        },
        popupOptions: {
          closeButton: false,
          autoClose: false
        },
        mapData: {
          zoom: 4,
          maxZoom: 14,
          minZoom: 2,
          options: {
            zoomControl: false,
            keyboard: false,
            scrollWheelZoom: false
          },
          controlZoomPosition: 'bottomright',
          center: [48.8664982, 2.3348235],
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
          url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
        }
      }
    },
    mounted () {
      EventBus.$on('shipment:loaded', this.fetchItinerary)
    },
    methods: {
      async fetchItinerary () {
        const shipment = this.getCurrentShipment
        if (shipment) {
          await this.retrieveItinerary()

          /**
           * According to the state of the shipment, either zoom to the markers
           * or zoom to a specific marker.
           */
          const pendingStates = ['pending', 'late']
          const inProgressStates = ['started', 'transit', 'near_delivery', 'delivered']
          const finishedStates = ['finished', 'proof_of_delivery_available']

          const { pickup, delivery, state } = shipment
          const isPlanned = state === 'planned'
          const isFinished = finishedStates.includes(state)

          const isPickupPending = inProgressStates.includes(state) &&
            pendingStates.includes(pickup.state)
          const isDeliveryPending = inProgressStates.includes(state) &&
            pendingStates.includes(delivery.state)
          const isPickupCompleted = pickup.state === 'completed' ||
            isFinished
          const isDeliveryCompleted = shipment.delivery.state === 'completed' ||
            isFinished

          this.canFlyToItinerary = false
          if (isPlanned || isPickupPending) {
            this.flyToMarker('pickup')
            if (isPickupCompleted) {
              this.addCompletedTooltip('pickup')
            } else if (isPlanned) {
              this.addPlannedTooltip()
              this.hasDistance = true
            } else {
              this.addInProgressTooltip('pickup')
              this.hasDistance = true
            }
          } else if (!isPickupPending && isDeliveryPending) {
            this.flyToMarker('delivery')
            if (isDeliveryCompleted) this.addCompletedTooltip('delivery')
            else {
              this.addInProgressTooltip('delivery')
              this.hasDistance = true
            }
          } else {
            this.canFlyToItinerary = true
            this.flyToMarkers()
            if (isPickupCompleted && isDeliveryCompleted) {
              this.addCompletedTooltip('pickup')
              this.addCompletedTooltip('delivery')
            }
          }

          /**
           * Once the zooming animations are over, show the map itinerary
           */
          // @ts-ignore
          if (!this.$refs.map || !this.$refs.map.mapObject) return
          // @ts-ignore
          const map = this.$refs.map.mapObject
          if (map) {
            let zoomed = false
            map.on('zoomend', () => {
              if (zoomed) return
              zoomed = true
              this.itineraryVisible = true

              this.$nextTick(() => {
                if (this.canFlyToItinerary) this.flyToItinerary()
              })
            })
          }
        }
      },
      /**
       * @function addTooltip
       * @param {string} direction
       * @param {string} content
       * @param {object} options
       */
      async addTooltip (direction, content, options) {
        await this.$nextTick()
        // @ts-ignore
        const marker = direction && this.$refs[direction] && this.$refs[direction].mapObject
        if (marker) {
          marker.bindTooltip(content, {
            className: `tooltip-${direction}`,
            permanent: true,
            ...options
          }).openTooltip()
        }
      },
      /**
       * Adds a tooltip for the marker, with the completed textual content
       * @function addCompletedTooltip
       * @param {string} direction
       * @returns {Promise<any>}
       */
      async addCompletedTooltip (direction) {
        await this.$nextTick()

        /**
         * According to the pickup & delivery positions in the screen, suppose the
         * bearing of the pickup->delivery itinerary.
         */
        // @ts-ignore
        if (!this.$refs.map || !this.$refs.map.mapObject) return false
        // @ts-ignore
        const map = this.$refs.map.mapObject
        const { pickup, delivery } = this.$refs
        const pos = {
          // @ts-ignore
          pickup: map.latLngToLayerPoint(pickup.mapObject.getLatLng()),
          // @ts-ignore
          delivery: map.latLngToLayerPoint(delivery.mapObject.getLatLng())
        }
        const bearing = pos.delivery.x - pos.pickup.x

        const content = `${this.$t(direction === 'pickup'
          ? 'shipment.labels.pickup_done'
          : 'shipment.labels.delivery_done')}`

        /**
         * Depending of the direction & bearing, set the direction & the offset accordingly.
         */
        this.addTooltip(direction, content, {
          opacity: 1,
          direction: direction === 'pickup'
            ? (bearing > 0
              ? 'left'
              : 'right')
            : (bearing > 0
              ? 'right'
              : 'left'),
          offset: direction === 'pickup'
            ? (bearing > 0
              ? [-10, 0]
              : [10, 0])
            : (bearing > 0
              ? [10, 0]
              : [-10, 0])
        })
      },
      /**
       * Adds a tooltip for the marker, with the in progress textual content
       * @function addInProgressTooltip
       * @param {string} direction
       */
      addInProgressTooltip (direction) {
        const dir = this.hasMission
          ? this.getCurrentShipment.mission[direction]
          : this.getCurrentShipment[direction]

        const { date: handlingDate } = dir.time_slot

        const date = handlingDate
          ? `<br /><span class="tw-font-medium tw-text-white">${this.$moment(handlingDate).format('LL')}</span>`
          : ''

        const content = `${this.$t(direction === 'pickup' ? 'shipment.labels.pickup_planned_at' : 'shipment.labels.delivery_planned_at')} ${date}`

        /**
         * On a mobile viewport, we want to change the direction of the tooltip, to avoid
         * being cropped.
         */
        const isMobile = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 455
        const tooltipDirection = isMobile ? 'top' : 'left'

        this.addTooltip(direction, content, {
          opacity: 1,
          className: `tooltip-${direction} ${direction === 'pickup' ? 'tw-bg-blue-500' : 'tw-bg-green-500'}`,
          direction: tooltipDirection,
          offset: isMobile ? [0, -40] : [-10, 0]
        })
      },
      /**
       * Adds a tooltip for the marker, with the planned textual content
       * @function addPlannedTooltip
       */
      addPlannedTooltip () {
        const shipment = this.getCurrentShipment
        const dir = this.hasMission
          ? shipment.mission.pickup
          : shipment.pickup

        const handlingDate = dir.time_slot.date
        const date = handlingDate
          ? `<br /><span class="tw-font-medium tw-text-white">${this.$moment(handlingDate).fromNow(true)}</span>`
          : ''

        const content = `${this.$t('shipment.labels.planned')} ${date}`

        this.addTooltip('pickup', content, {
          opacity: 1,
          className: 'tooltip-pickup tw-bg-blue-500',
          direction: 'left',
          offset: [-10, 0]
        })
      },
      /**
       * @function formatPopup
       * @param {string} direction
       */
      formatPopup (direction) {
        const dir = this.hasMission
          ? this.getCurrentShipment.mission[direction]
          : this.getCurrentShipment[direction]

        const { address } = dir

        const comment = `
          <div class="content tw-text-gray-700">
            ${address.comment}
          </div>
        `
        return `
          <div class="header ${direction}">
            <b>${address.name}</b><br>
            ${address.street_name}<br>
            ${address.postal_code} ${address.city}, ${this.$t(address.country)}
          </div>
          ${address.comment ? comment : ''}
        `
      },
      /**
       * @function flyToMarker
       * @param {string} direction
       */
      flyToMarker (direction) {
        // @ts-ignore
        this.readyToFly((map) => {
          // @ts-ignore
          const marker = this.$refs[direction].mapObject
          if (map && marker) {
            this.itineraryVisible = false
            map.flyTo(marker.getLatLng(), 14, this.flyOptions)
          }
        })
      },
      /**
       * Make the camera fly to the boundaries of the markers
       * @function flyToMarkers
       */
      flyToMarkers () {
        // @ts-ignore
        this.readyToFly((map) => {
          // @ts-ignore
          const markers = this.$refs.markers.mapObject
          if (map && markers) {
            this.itineraryVisible = false
            map.flyToBounds(markers.getBounds(), this.flyOptions)
          }
        })
      },
      /**
       * Make the camera fly to the boundaries of the itinerary
       * @function flyToItinerary
       */
      flyToItinerary () {
        // @ts-ignore
        this.readyToFly((map) => {
          // @ts-ignore
          if (this.$refs.itinerary && this.$refs.itinerary.mapObject) {
            // @ts-ignore
            const itinerary = this.$refs.itinerary.mapObject
            map.flyToBounds(itinerary.getBounds(), this.flyOptions)
          }
        })
      },
      /**
       * Calls the callback once we're ready to make the flyTo animation
       * @function readyToFly
       * @param {function} callback
       */
      readyToFly (callback) {
        setTimeout(() => {
          // @ts-ignore
          if (!this.$refs.map || !this.$refs.map.mapObject) return
          // @ts-ignore
          const map = this.$refs.map.mapObject
          map.invalidateSize()
          this.$nextTick(() => {
            callback(map)
          })
        }, 300)
      },
      /**
       * @function retrieveItinerary
       * @return {Promise<any>}
       */
      retrieveItinerary () {
        return Shipment.travel({
          cid: this.getCid,
          sid: this.getCurrentShipment.uuid
        })
          .then(res => {
            this.itinerary = res.data.itinerary.coordinates.map((/** @type {[number, number]} */ location) => [
              location[1],
              location[0]
            ])

            if (this.canFlyToItinerary) this.flyToItinerary()
          })
          .catch(() => {})
      }
    },
    beforeDestroy () {
      EventBus.$off('shipment:loaded', this.fetchItinerary)
    }
  })
</script>

<style lang="scss">

  .shipment-detail-status-map {
    .leaflet-map {
      height: 280px;
      z-index: 0;
    }

    &__distance {
      background-color: rgba(white, 0.8);
      padding: 4px 16px;
      z-index: 500;
      text-align: center;
      box-shadow: 0 3px 5px rgba(black, 0.12);
      margin: auto;

      &__container {
        position: absolute;
        bottom: 16px;
      }
    }

    .leaflet-tooltip {
      font-size: 14px;
      font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      padding: 8px 16px;

      &.tooltip-pickup.tw-bg-blue-500 {
        border: none;
        color: white;

        &.leaflet-tooltip-top::before {
          border-top-color: $info;
        }

        &.leaflet-tooltip-left::before {
          border-left-color: $info;
        }
      }

      &.tooltip-delivery.tw-bg-green-500 {
        border: none;
        color: white;

        &.leaflet-tooltip-top::before {
          border-top-color: $primary;
        }

        &.leaflet-tooltip-left::before {
          border-left-color: $primary;
        }
      }
    }
  }

</style>
