<template>
  <div class="shipment-price-detail-booking">
    <div class="tw-flex">
      <div
        v-text="$t('shipment.titles.billing_carrier_booking', {
          carrier: booking && booking.driver && booking.driver.carrier ? booking.driver.carrier.name : '-'
        })"
        class="tw-font-medium tw-flex-1"
        data-test="header"
      />
      <div class="tw-flex tw-justify-end tw-items-start">
        <PriceTotal
          :price-excluded="$t('price_excl_tax', {
            price: $options.filters.currency(booking.billing.price, booking.billing.currency, $i18n.locale)
          })"
          :price-included="$t('price_incl_tax', {
            price: $options.filters.currency(booking.billing.vat_included_price, booking.billing.currency, $i18n.locale)
          })"
          data-test="total"
        />
        <UiButton
          :title="isOpen
            ? $t('shipment.labels.hide_price_detail')
            : $t('shipment.labels.show_price_detail')"
          v-b-tooltip.hover
          variant="link"
          class="tw-ml-2"
          @click="isOpen = !isOpen"
          data-test="toggle-button"
        >
          <template #left-icon>
            <UiMaterialIcon
              :class="{
                'tw-transform tw-rotate-180': isOpen
              }"
              class="tw-transition-transform"
              name="keyboard_arrow_down"
              data-test="icon"
            />
          </template>
        </UiButton>
      </div>
    </div>

    <BCollapse
      id="collapse"
      v-model="isOpen"
      class="mt-2"
    >
      <PricingLayout
        v-if="booking.billing.lines"
        class="mb-2"
      >
        <PricingLines
          v-for="line in booking.billing.lines"
          :key="line.key"
          :label="line.label"
          :cancelled="line.is_cancelled"
          :comment="line.comment"
        >
          <span class="tw-text-blue-500">
            {{ line.price | currency(booking.billing.currency, $i18n.locale) }}
          </span>
        </PricingLines>
      </PricingLayout>
    </BCollapse>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import PriceTotal from '@/components/CtkPricing/_subs/PriceTotal/index.vue'

  /**
   * @module component - ShipmentPriceDetailBooking
   * @param {object} booking
   */
  export default defineComponent({
    name: 'ShipmentPriceDetailBooking',
    components: {
      PricingLayout,
      PriceTotal,
      PricingLines
    },
    props: {
      booking: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isOpen: false
      }
    }
  })
</script>

<style lang="scss">
.shipment-price-detail-booking__header {
  border-bottom: 1px solid $divider;
}
.shipment-price-detail-booking .pricing-layout {
  --tw-border-opacity: 1;
  border-top-color: rgba(40, 118, 150, var(--tw-border-opacity));
  border-radius: 0px;
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.5rem;
}
.shipment-price-detail-booking .pricing-lines {
  border-width: 0px;
  padding-top: 0px;
  padding-bottom: 0.25rem;
}
.shipment-price-detail-booking .price-chip {
  padding: 0.5rem;
  min-width: 110px;
}
</style>
