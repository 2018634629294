<template>
  <ctk-dialog
    v-model="value"
    modal-class="shipment-cancel-dialog"
    id="shipment-cancel-dialog"
    max-width="480px"
    hide-header
    hide-footer
    persistent
    @input="close"
  >
    <div
      class="tw-flex tw-flex-col tw-flex-1 tw-p-4 md:tw-p-8"
    >
      <shipment-payment-confirmation-view-close-button
        data-test="close-button"
        @close="close"
      />

      <picture class="tw-mb-8 tw-mx-auto">
        <img
          src="@/assets/img/icons/rejected.svg"
          width="64"
          height="64"
          alt=""
          data-test="cancel-icon"
        >
      </picture>
      <h1
        v-text="$t('shipment.titles.cancel')"
        class="tw-text-2xl tw-mb-6 tw-text-center"
        data-test="title"
      />

      <div
        v-if="step === 1"
        data-test="step-reasons"
      >
        <i18n
          tag="p"
          path="shipment.paragraphs.cancellation_explanation"
          class="tw-mb-4"
          data-test="explanation"
        >
          <template #reference>
            <span
              v-text="reference"
              class="tw-whitespace-nowrap"
            />
          </template>
        </i18n>

        <b-form-radio-group
          v-model="formData.reason"
          :disabled="$wait.is('cancelling shipment')"
          data-test="reasons"
          name="reason"
          stacked
          class="focus:tw-outline-none"
        >
          <b-form-radio
            v-for="reason in reasons"
            :key="reason.value"
            :value="reason.value"
            class="reason-radio tw-mb-3"
          >
            {{ reason.text }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div
        v-else-if="step === 2"
        data-test="step-contact"
      >
        <p
          v-text="$t('shipment.paragraphs.cancellation_edit')"
          data-test="edit"
        />

        <p
          v-text="$t('shipment.paragraphs.cancellation_contact')"
          data-test="contact"
        />

        <div
          class="tw-rounded tw-flex tw-items-center tw-py-2 tw-pr-4 tw-pl-3 tw-bg-gray-100 tw-border-l-4 tw-border-solid tw-border-0 tw-border-blue-500"
          data-test="phone-section"
        >
          <ui-ctk-icon
            name="phone-help"
            class="tw-text-blue-500 tw-mr-2 tw-text-4xl"
            data-test="icon"
          />
          <div
            class="tw-flex tw-flex-col tw-justify-center"
          >
            <div
              v-text="$t('shipments.titles.contact_us')"
              class="tw-text-base"
              data-test="label"
            />
            <UiLink
              v-text="phone.text"
              :href="`tel:${phone.value}`"
              data-test="phone"
            />
          </div>
        </div>

        <ValidationProvider
          ref="ignore-provider"
          key="ignore"
          name="ignore"
          rules="required|length:4"
          data-test="ignore-provider"
        >
          <template slot-scope="{ invalid, validated }">
            <b-form-checkbox
              v-model="formData.ignore"
              :state="invalid && validated ? false : null"
              class="tw-text-secondary-text tw-mt-4"
              data-test="ignore"
              value="true"
              required
            >
              {{ $t('shipment.paragraphs.contact_ignore') }}
            </b-form-checkbox>
          </template>
        </ValidationProvider>
      </div>
      <div
        v-else-if="step === 3"
        data-test="step-tos"
      >
        <template
          v-if="shipment.state === 'available'"
        >
          <div class="tw-rounded tw-p-4 tw-bg-gray-100">
            <p
              v-text="$t('shipment.paragraphs.nothing_to_pay')"
              class="tw-mb-0"
              data-test="nothing-to-pay"
            />
            <p
              v-if="isPrePaid"
              v-text="$t('shipment.paragraphs.not_charged')"
              class="tw-mb-0 tw-mt-3"
              data-test="not-charged"
            />
          </div>
        </template>
        <template
          v-else
        >
          <i18n
            tag="p"
            path="shipment.paragraphs.cancellation_fees"
            data-test="tos"
          >
            <template #tos>
              <UiLink
                :href="tos"
                target="_blank"
                v-text="$t('shipment.labels.tos')"
                data-test="link"
              />
            </template>
          </i18n>

          <p
            v-if="isPrePaid"
            v-text="$t(shipment.billing && shipment.billing.payment_plan === 'booking_cc'
              ? 'shipment.paragraphs.cancellation_refund_paid'
              : 'shipment.paragraphs.cancellation_refund')"
            data-test="refund"
          />

          <ValidationProvider
            ref="tos-provider"
            key="tos"
            name="tos"
            rules="required|length:4"
            data-test="tos-provider"
          >
            <template slot-scope="{ invalid, validated }">
              <b-form-checkbox
                v-model="formData.tos"
                :state="invalid && validated ? false : null"
                class="tw-text-secondary-text tw-mt-4"
                data-test="terms"
                value="true"
                required
              >
                {{ $t('shipment.paragraphs.tos_acknowledge') }}
              </b-form-checkbox>
            </template>
          </ValidationProvider>
        </template>
      </div>
      <div
        v-else-if="step === 4"
        data-test="step-comment"
      >
        <p
          v-text="$t('shipment.paragraphs.cancellation_comment')"
          data-test="explanation"
        />

        <ValidationObserver
          ref="observer"
        >
          <form
            class="tw-w-full tw-flex"
          >
            <ValidationProvider
              :name="$t('shipment.fields.comment')"
              rules="required"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-textarea
                  v-model="formData.comment"
                  :label="$t('shipment.labels.cancellation_comment')"
                  :error="invalid && validated"
                  :hint="invalid ? errors[0] : null"
                  :data-vv-as="$t('shipment.fields.comment')"
                  :disabled="$wait.is('cancelling shipment')"
                  rows="8"
                  id="comment"
                  name="comment"
                  class="tw-w-full"
                  required
                  data-test="comment"
                />
              </template>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div
      class="tw-flex tw-justify-between tw-items-center tw-flex-col-reverse md:tw-flex-row tw-w-full tw-p-4 md:tw-px-8 md:tw-py-6"
    >
      <ui-button
        type="button"
        variant="link"
        class="tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0"
        data-test="cancel-button"
        @click.prevent="close"
      >
        {{ $t('cancel') | capitalize }}
      </ui-button>

      <ui-button
        v-if="step < 4"
        :disabled="isNextButtonDisabled"
        variant="danger"
        outline
        class="tw-w-full md:tw-w-auto tw-w-full"
        data-test="next-button"
        @click="nextStep"
      >
        {{ $t('shipment.buttons.next') }}
      </ui-button>

      <ui-button
        v-if="step === 4"
        :loading="$wait.is('cancelling shipment')"
        :disabled="$wait.is('cancelling shipment')"
        variant="danger"
        class="tw-w-full md:tw-w-auto"
        data-test="confirmation-button"
        @click="submitted"
      >
        {{ $t('shipment.buttons.confirm_cancellation') }}
      </ui-button>
    </div>
  </ctk-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent, ref, readonly, reactive, toRefs, computed } from '@vue/composition-api'

  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import { EventBus } from '@/services/EventBus'
  import Config from '@/services/Config'
  import Hotjar from '@/plugins/VueHotjar'
  // @ts-ignore
  import chronoinfos from '@/../config/chronoinfos'

  import useI18n from '@/composables/useI18n'
  import useWait from '@/composables/useWait'
  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'
  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import ShipmentPaymentConfirmationViewCloseButton from '@/views/Shippers/components/ShipmentPaymentConfirmationView/_subs/ShipmentPaymentConfirmationViewCloseButton/index.vue'

  /**
   * @module component - ShipmentCancelDialog
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentCancelDialog',
    components: {
      CtkDialog,
      CtkInputSelect,
      CtkInputTextarea,
      ShipmentPaymentConfirmationViewCloseButton
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      /** @type {any} */
      const { shipment } = toRefs(props)
      const i18n = useI18n()
      const wait = useWait()

      const reasonKeys = ['error', 'shipment_has_changed', 'shipper_found_another_carrier', 'shipment_cancelled', 'other']
        .map(reason => ({
          text: i18n.value.t(`shipment.values.cancellation_reason.${reason}`),
          value: reason
        }))

      const reasons = readonly(reasonKeys)
      /**
       * @type {{
       *  reason: string|null
       *  comment: string|null
       *  tos: boolean
       *  ignore: boolean
       * }}
       */
      const formData = reactive({
        reason: null,
        comment: null,
        tos: false,
        ignore: false
      })

      const phone = readonly(chronoinfos.phones.cancellation)
      const step = ref(1)
      const stepName = computed(() => {
        const steps = ['reasons', 'contact', 'terms', 'comment']
        return steps[step.value - 1]
      })

      const tos = `${Config.get('wwwBase')}goto/tos`
      const reference = ref(shipment.value.shipper_reference || shipment.value.reference)
      const isPrePaid = computed(() => ['immediate_cc', 'immediate_cc_authorization', 'booking_cc'].includes(shipment.value.billing && shipment.value.billing.payment_plan))
      const hasContactStep = computed(() => {
        if (!formData.reason) return false

        const matchesReason = ['error', 'shipment_has_changed'].includes(formData.reason)
        const hasBeenBooked = shipment.value.state !== 'available'

        return matchesReason && hasBeenBooked
      })

      const isNextButtonDisabled = computed(() => {
        if (wait.is('cancelling shipment')) return true
        if (!formData.reason) return true

        // Contact step
        if (step.value === 2 && hasContactStep && !formData.ignore) return true
        // Terms step
        if (step.value === 3 && shipment.value.state !== 'available' && !formData.tos) return true

        return false
      })

      function reset () {
        step.value = 1
        formData.reason = null
        formData.comment = null
        formData.tos = false
        formData.ignore = false
      }

      return {
        step,
        stepName,
        tos,
        phone,
        isPrePaid,
        hasContactStep,
        isNextButtonDisabled,
        reference,
        reasons,
        formData,
        reset
      }
    },
    computed: {
      ...mapGetters('auth', ['getCid'])
    },
    methods: {
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          // @ts-ignore
          .then(valid => {
            if (!valid || this.$wait.is('cancelling shipment')) return
            if (this.$matomo) {
              this.$matomo.trackEvent('Shipments', 'Confirmed Cancellation', this.shipment.uuid)
            }

            this.$wait.start('cancelling shipment')

            const { reason, comment } = this.formData

            Shipment.cancel({
              cid: this.getCid,
              sid: this.shipment.uuid
            }, {
              reason,
              comment,
              booking: this.shipment.mission
                ? this.shipment.mission.uuid
                : null
            })
              .then(() => {
                this.$emit('input', false)
                this.reset()

                EventBus.$emit('shipment:refresh', () => {
                  showToaster(this, this.$t('shipment.paragraphs.cancelled', {
                    reference: this.shipment.reference
                  }), {
                    type: 'success',
                    position: 'bottom-right'
                  })
                })
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  /**
                   * If there was a conflict during the cancellation (eg. the shipment
                   * was already cancelled in the meantime), refresh the shipment view
                   */
                  if (err.response.status === 409) {
                    EventBus.$emit('shipment:refresh')
                    this.$emit('input', false)
                    this.reset()
                  }

                  const errorMessage = data.error.detail || data.error.title || this.$t('an_error_has_occurred')

                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => {
                this.$wait.end('cancelling shipment')
              })
          })
      },
      nextStep () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Next Step Cancellation', this.stepName)
        }

        Hotjar.tag(`Shipment Next Step "${this.stepName}" Cancellation`)

        if (this.step === 1) {
          if (this.hasContactStep) {
            this.step = 2
          } else {
            this.step = 3
          }
          return
        }

        this.step += 1
      },
      close () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Close Cancellation', this.stepName)
        }

        this.$emit('input', false)
        this.reset()
      }
    }
  })
</script>

<style lang="scss">
.shipment-cancel-dialog .modal-container {
  max-height: initial;
  min-height: initial;
}
@media (min-width: 770px) {
  .shipment-cancel-dialog .modal-container {
    min-height: 627px;
  }
}
.shipment-cancel-dialog .modal-wrapper {
  justify-content: unset;
  align-items: unset;
}
@media (min-width: 770px) {
  .shipment-cancel-dialog .modal-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.shipment-cancel-dialog .modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.shipment-cancel-dialog .modal-body::after {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  content: '';
  height: 20px;
}
</style>
