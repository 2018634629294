<template>
  <div class="shipment-payment-confirmation-view tw-px-4 md:tw-px-10 tw-pb-4 md:tw-pb-10 tw-pt-8 md:tw-pt-16">
    <shipment-payment-confirmation-view-close-button
      data-test="close-button"
      @close="$emit('close')"
    />

    <div class="shipment-payment-confirmation-view__wrapper tw-flex tw-flex-col tw-mx-auto tw-text-center tw-mb-4">
      <img
        src="@/assets/img/icons/confirmation.svg"
        width="80"
        height="80"
        alt=""
        class="tw-mb-8 tw-mx-auto"
        data-test="main-icon"
      >

      <h1
        v-text="$t('shipment.paragraphs.shipment_planned')"
        class="shipment-payment-confirmation-view__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap"
        data-test="title"
      />

      <i18n
        :path="contentKey"
        tag="p"
        class="tw-text-secondary-lighten tw-mb-6"
        data-test="email-paragraph"
      >
        <template #email>
          <span
            v-text="getUserInfos.email"
            class="tw-font-bold tw-whitespace-nowrap"
            data-test="email"
          />
        </template>
      </i18n>

      <UiButton
        :to="{
          name: 'Shipment',
          params: {
            uuid: shipment.uuid
          }
        }"
        class="tw-text-white tw-rounded-full tw-px-4 tw-mx-auto"
        variant="primary"

        data-test="view-shipment"
        @click.native="$emit('close')"
      >
        {{ $t('app.buttons.track_shipment') }}
      </UiButton>

      <div class="shipment-payment-confirmation-view__links tw-flex tw-justify-center">
        <UiLink
          :to="{
            name: 'NewShipment'
          }"
          class="tw-text-left tw-text-sm tw-mt-4"
          standalone
          data-test="new-shipment"
        >
          {{ $t('app.buttons.new_shipment') }}
        </UiLink>
      </div>
    </div>

    <img
      src="@/assets/img/illustrations/shipment_confirmation.svg"
      alt=""
      class="shipment-payment-confirmation-view__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-8 tw-hidden md:tw-block"
    >
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentPaymentConfirmationViewCloseButton from './_subs/ShipmentPaymentConfirmationViewCloseButton/index.vue'

  /**
   * @module component - ShipmentPaymentConfirmationView
   * @param {object} shipment - The shipment object
   * @param {string} contentKey - Default content translation key
   */
  export default {
    name: 'ShipmentPaymentConfirmationView',
    components: {
      ShipmentPaymentConfirmationViewCloseButton
    },
    props: {
      shipment: {
        type: Object,
        default: null
      },
      contentKey: {
        type: String,
        default: 'shipment.paragraphs.receipt_sent'
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.shipment-payment-confirmation-view__wrapper {
  max-width: 480px;
}
.shipment-payment-confirmation-view__links a:not(:last-child) {
  position: relative;
}
.shipment-payment-confirmation-view__links a:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  position: absolute;
  content: '';
  height: 1rem;
  width: 1px;
  right: -1rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.shipment-payment-confirmation-view__illustration {
  -o-object-fit: contain;
  object-fit: contain;
  height: 220px;
}
</style>
