<template>
  <ctk-dialog
    v-model="dialogValue"
    modal-class="shipment-payment-dialog"
    max-width="765px"
    hide-header
    hide-footer
    persistent
  >
    <!-- Payment view -->
    <shipment-payment-dialog-payment-view
      v-if="!paymentError && !paymentConfirmation"
      :stripe="stripe"
      :shipment="getCurrentShipment"
      @submitted="submitted"
      @close="close"
      data-test="payment-view"
    />

    <!-- Payment confirmation -->
    <shipment-payment-dialog-payment-confirmation-view
      v-if="paymentConfirmation && !paymentError"
      :shipment="getCurrentShipment"
      @close="close"
      data-test="payment-confirmation-view"
    />

    <!-- Payment error -->
    <shipment-payment-dialog-payment-error-view
      v-if="paymentError"
      @retry="paymentError = false"
      @close="close"
      data-test="payment-error-view"
    />
  </ctk-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Shipment } from '@/resources'
  import * as Sentry from '@sentry/browser'

  import useStripe from '@/composables/useStripe'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import ShipmentPaymentDialogPaymentView from './_subs/ShipmentPaymentDialogPaymentView/index.vue'
  import ShipmentPaymentDialogPaymentConfirmationView from './_subs/ShipmentPaymentDialogPaymentConfirmationView/index.vue'
  import ShipmentPaymentDialogPaymentErrorView from './_subs/ShipmentPaymentDialogPaymentErrorView/index.vue'
  import { showToaster } from '@/services/Toaster'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - ShipmentPaymentDialog
   */
  export default {
    name: 'ShipmentPaymentDialog',
    components: {
      CtkDialog,
      ShipmentPaymentDialogPaymentView,
      ShipmentPaymentDialogPaymentConfirmationView,
      ShipmentPaymentDialogPaymentErrorView
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        paymentError: false,
        paymentConfirmation: false
      }
    },
    setup (props) {
      const { stripe } = useStripe()
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        stripe,
        dialogValue
      }
    },
    watch: {
      value (v) {
        if (!v) {
          /**
           * Set the default values whenever the user closes the dialog
           */
          this.paymentError = false
          this.paymentConfirmation = false
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      loaderKey () {
        return `paying shipment ${this.getCurrentShipment.uuid}`
      }
    },
    methods: {
      close () {
        this.dialogValue = false
      },
      /**
       * @function acceptationSuccessHandler
       */
      acceptationSuccessHandler () {
        EventBus.$emit('shipment:refresh')

        this.paymentConfirmation = true
        this.$wait.end(this.loaderKey)
      },
      /**
       * @function acceptationErrorHandler
       * @param {any} err
       */
      acceptationErrorHandler (err) {
        const data = err.response && err.response.data
        if (!data) {
          this.$wait.end(this.loaderKey)
          return
        }

        if (err.response.status === 402) {
          try {
            /**
             * Handle Stripe next actions if required
             */
            const paymentMethod = data.payment_method
            switch (paymentMethod.status) {
            case 'requires_auth':
              this.$wait.start(this.loaderKey)
              this.stripe.handleCardAction(paymentMethod.secret)
                .then((/** @type {any} */ cardResponse) => {
                  if (cardResponse.error) {
                    this.paymentError = true

                    this.$wait.end(this.loaderKey)
                  } else {
                    /**
                     * Got a positive response from Stripe, retry
                     * to submit the acceptation with the payment intent.
                     */
                    this.submitted({
                      id: cardResponse.paymentIntent.id,
                      type: 'stripe_payment_intent'
                    })
                  }
                })
              break
            case 'refused':
              this.paymentError = true
              this.$wait.end(this.loaderKey)
              break
            default:
              throw new Error(`Could not handle "${data.payment_method.status}" status.`)
            }
          } catch (e) {
            this.$wait.end(this.loaderKey)
            Sentry.captureException(e)
            showToaster(this, e.message, { type: 'error' })
          }
        } else {
          this.$wait.end(this.loaderKey)

          this.paymentConfirmation = false

          if (data.error) {
            /**
             * Show the violation message in the input
             */
            if (data.error.violations) {
              try {
                /**
                 * TODO: Use the handlePropertyPathViolations handler.
                 */
                data.error.violations.forEach(({ property_path: property, message }) => {
                  const provider = this.$refs[`${property}-provider`]
                  if (!provider) {
                    throw new Error(`The provider for the violation "${property}" does not exists.`)
                  }

                  provider.setErrors([
                    message
                  ])
                })
              } catch (e) {
                Sentry.captureException(e)
                showToaster(this, e.message, { type: 'error' })
              }
            } else {
              const errorMessage = data.error.detail || data.error.title || data.error.message || this.$t('an_error_has_occurred')
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          }

          // * exception detail loggin purpose
          Sentry.captureException(
            new Error('Could not accept the bid'),
            {
              extra: {
                err,
                data,
                component: this.$options.name
              }
            }
          )
        }
      },
      /**
       * Accepts the current proposal
       * @function submitted
       * @param {object} paymentMethod
       * @param {string} paymentMethod.id
       * @param {string} paymentMethod.type
       */
      submitted (paymentMethod) {
        this.$wait.start(this.loaderKey)

        const { uuid, mission } = this.getCurrentShipment
        Shipment.pay({
          cid: this.getCid,
          sid: uuid,
          bid: mission.uuid
        }, {
          payment_method: paymentMethod
        })
          .then(this.acceptationSuccessHandler)
          .catch(this.acceptationErrorHandler)
      }
    }
  }
</script>

<style lang="scss">
.shipment-payment-dialog__content {
  padding: 2rem;
  position: relative;
}
.shipment-payment-dialog__content__title {
  position: relative;
  font-size: 22px;
  left: -8px;
}
.shipment-payment-dialog__content__title span, .shipment-payment-dialog__content__title .ctk-font {
  margin: auto 0;
}
.shipment-payment-dialog__content__title .ctk-font {
  color: $proposal-pending;
}
.shipment-payment-dialog__footer__cancel {
  margin: auto 0;
}
.shipment-payment-dialog .modal-wrapper {
  justify-content: unset;
  align-items: unset;
}
.shipment-payment-dialog .modal-container {
  max-height: initial;
  margin: auto !important;
  width: 100%;
}
</style>
