<template>
  <section
    id="billing-section"
    class="shipment-billing-detail tw-bg-white border-gray tw-rounded tw-p-12"
  >
    <h4
      v-text="$t('shipment.titles.billing')"
      class="tw-mb-6"
      data-test="title"
    />

    <div class="shipment-billing-detail__content tw-flex tw-flex-col">
      <shipment-billing-invoice
        v-for="invoice in getCurrentShipment.invoices"
        :key="invoice.uuid"
        :invoice="invoice"
        class="tw-w-full mb-3"
      />
    </div>

    <div
      v-if="getCurrentShipment.state === 'cancelled' && (getCurrentShipment.billing.payment_plan === 'immediate_cc' || getCurrentShipment.billing.payment_plan === 'immediate_cc_authorization') && !$wait.is('loading shipment billing')"
      class="shipment-billing-detail__explanation tw-flex tw-items-center tw-w-full tw-flex-1 tw-rounded"
      data-test="tos"
    >
      <i18n
        class="my-4 ml-4 mb-0 tw-flex-1"
        data-test="cancellation-without-fees"
        path="shipment.paragraphs.price_cancellation_with_fees"
        tag="p"
      >
        <template #tos>
          <UiLink
            :href="tos"
            v-text="$t('shipment.labels.tos')"
          />
        </template>
      </i18n>

      <img
        src="~@/assets/img/illustrations/price.svg"
        alt=""
      >
    </div>

    <div class="shipment-billing-detail__bookings mt-4">
      <ui-loader
        v-if="$wait.is('loading shipment billing')"
        :size="40"
        type="pallet"
        background-back="#000"
      />
    </div>
  </section>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import Config from '@/services/Config'
  import ShipmentBillingInvoice from './_subs/ShipmentBillingInvoice/index.vue'

  /**
   * @module component - ShipmentBillingDetail
   * @param {Array<any>} invoicesStory - Passes a list of invoices
   */
  export default defineComponent({
    name: 'ShipmentBillingDetail',
    props: {
      invoicesStory: {
        type: Array,
        default: null
      }
    },
    components: {
      ShipmentBillingInvoice
    },
    data () {
      return {
        tos: `${Config.get('wwwBase')}goto/tos`
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-billing-detail {
    border: 1px solid $divider;

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 32px !important;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px !important;
    }

    &__explanation {
      position: relative;
      background-color: $light-gray;

      @media only screen and (max-width: $breakpoint-laptop-s) and (min-width: $breakpoint-tablet), screen and (max-width: $breakpoint-mobile-l) {
        img {
          display: none;
        }
      }
    }
  }

</style>
