<template>
  <div class="shipment-item tw-rounded">
    <ctk-item
      :to="{
        name: 'Shipment',
        params: {
          uuid: shipment.uuid
        }
      }"
      :event="''"
      :class="{
        'shipment-item__link--need-payment': needPayment
      }"
      class="tw-flex tw-flex-col shipment-item__link tw-rounded"
      touchable-type="router-link"
      @click.native="clicked"
    >
      <shipment-item-header
        :shipment="shipment"
        :has-expiration="hasAvailableState && !needPayment"
        :has-infos="hasInfos"
      />
      <shipment-item-content
        :shipment="shipment"
        :has-options="hasAvailableState"
        :has-price="hasAvailableState || hasAwaitingAcceptationState"
        :has-tracking="allowedStatusWithTracking.includes($route.params.state)"
        :has-cancellation-dates="cancelledStatus.includes($route.params.state) && !!shipment.cancellation"
        :has-rating="hasRating"
      />
    </ctk-item>

    <shipment-item-proposals
      v-if="shipment.pending_proposal_count > 0 && $route.params.state === 'available' && !isCorridor"
      :shipment="shipment"
    />
    <shipment-item-footer
      :shipment="shipment"
      :has-tracking="allowedStatusWithTracking.includes($route.params.state)"
      :has-cancellation-dates="cancelledStatus.includes($route.params.state) && !!shipment.cancellation"
      :has-rating="hasRating"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkItem from '@/components/CtkItem/index.vue'
  import ShipmentItemHeader from './_subs/ShipmentItemHeader/index.vue'
  import ShipmentItemContent from './_subs/ShipmentItemContent/index.vue'
  import ShipmentItemFooter from './_subs/ShipmentItemFooter/index.vue'
  import ShipmentItemProposals from './_subs/ShipmentItemProposals/index.vue'

  /**
   * @module component - ShipmentItem
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentItem',
    components: {
      CtkItem,
      ShipmentItemHeader,
      ShipmentItemContent,
      ShipmentItemFooter,
      ShipmentItemProposals
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        allowedStatusWithCarrier: ['planned', 'in_progress'],
        allowedStatusWithInfos: ['planned', 'in_progress'],
        allowedStatusWithTracking: ['in_progress', 'completed'],
        cancelledStatus: ['cancelled', 'refused']
      }
    },
    computed: {
      /**
       * @function isCorridor
       * @returns {boolean}
       */
      isCorridor () {
        return this.shipment.price_type === 'corridor'
      },
      /**
       * @function hasAvailableState
       * @returns {boolean}
       */
      hasAvailableState () {
        return this.$route.params.state === 'available'
      },
      /**
       * @function hasAwaitingAcceptationState
       * @returns {boolean}
       */
      hasAwaitingAcceptationState () {
        return this.$route.params.state === 'awaiting_acceptation'
      },
      /**
       * @function hasInfos
       * @returns {boolean}
       */
      hasInfos () {
        if (this.needPayment) return true

        const shipmentStatusAllowInfos = ['planned', 'transit']
        return this.allowedStatusWithInfos.includes(this.$route.params.state) && shipmentStatusAllowInfos.includes(this.shipment.state)
      },
      /**
       * @function hasRating
       * @returns {boolean}
       */
      hasRating () {
        const { mission } = this.shipment
        return (mission && mission.actions && mission.actions.can_be_rated) ||
          (mission && !!mission.rating)
      },
      /**
       * @function needPayment
       * @returns {boolean}
       */
      needPayment () {
        const { billing } = this.shipment
        return billing && billing.need_payment
      }
    },
    methods: {
      /**
       * Intercept the click on the shipment item to either show the rating dialog
       * or redirect the user to the shipment view.
       * @function clicked
       */
      clicked (e) {
        try {
          const path = typeof e.composedPath === 'undefined'
            ? e.path
            : e.composedPath()

          const el = path.find(el => el.dataset && el.dataset.test === 'rate-button')
          const isDisabled = el && el.getAttribute('disabled') === 'disabled'
          if (el && !isDisabled) {
            e.preventDefault()
            return
          }

          if (this.$matomo) {
            this.$matomo.trackEvent('Shipments', 'Clicked', this.shipment.uuid)
          }

          this.$router.push({
            name: 'Shipment',
            params: {
              uuid: this.shipment.uuid
            }
          })
            .catch(() => {})
        } catch (e) {
          this.$router.push({
            name: 'Shipment',
            params: {
              uuid: this.shipment.uuid
            }
          })
            .catch(() => {})
        }
      }
    }
  })
</script>

<style lang="scss">
.shipment-item {
  position: relative;
  background-color: white;
  border: 1px solid #E9E9EA;
}
.shipment-item__link {
  position: relative;
}
.shipment-item__link .shipment-item-header, .shipment-item__link .shipment-item-content {
  transition: background-color 200ms ease;
}
.shipment-item__link:hover .shipment-item-header {
  background-color: #DADADB;
}
.shipment-item__link:hover .shipment-item-content {
  background-color: #F3F3F3;
}
.shipment-item__link:hover .ctk-item-tracking__label, .shipment-item__link:hover .ctk-item-tracking-divider:not(.active), .shipment-item__link:hover .ctk-item-tracking-dot:not(.active):not(.completed) {
  background-color: white;
}
.shipment-item__link:hover .ctk-item-tracking__arrow {
  border-color: white transparent transparent transparent;
}
.shipment-item__link:focus {
  position: relative;
  outline: none;
  box-shadow: 0 1px 3px rgba(black, 0.2);
}
.shipment-item__link:focus .shipment-item-header {
  background-color: darken($divider, 10%);
}
.shipment-item__link:focus .shipment-item-content {
  background-color: $divider;
}
.shipment-item__link--need-payment::after {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 4px;
  border-radius: 4px 0 0 0;
}
@media (min-width: 375px) {
  .shipment-item__link--need-payment::after {
    border-radius: 4px 0 0 4px;
  }
}
.shipment-item-footer {
  display: none;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .shipment-item .shipment-item-footer {
    display: flex;
    min-height: 16px;
  }
}
</style>
