<template>
  <div class="shipment-detail-contact">
    <template
      v-if="hasContactInformations"
    >
      <address
        :class="[direction]"
        data-test="contact"
        class="shipment-detail-contact__contact tw-bg-gray-200 tw-rounded tw-px-4 tw-py-3 tw-pb-5"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          <div
            v-text="$t('shipment.labels.contact_informations')"
            data-test="title"
            class="tw-font-medium tw-text-sm tw-text-secondary"
          />
        </div>
        <div class="tw-flex tw-justify-between tw-items-end tw-truncate">
          <div class="tw-flex tw-flex-col tw-text-sm tw-truncate tw-pr-4 tw-leading-tight">
            <div
              v-if="contact.name"
              class="tw-font-medium tw-uppercase tw-text-secondary tw-truncate"
              data-test="name"
              v-text="contact.name"
            />
            <div
              v-if="contact.phone"
              data-test="phone"
              class="shipment-detail-contact__contact__phone tw-text-secondary-text"
              v-text="$options.filters.telephone(contact.phone)"
            />
          </div>
          <div>
            <UiLink
              v-if="hasEdit"
              class="tw-text-sm"
              href="#"
              standalone
              data-test="edit"
              v-text="$options.filters.capitalize($t('edit'))"
              @click.prevent="edit"
            />
          </div>
        </div>
      </address>
    </template>
    <template
      v-else
    >
      <div class="tw-flex tw-justify-start tw-items-center">
        <UiButton
          v-if="hasEdit"
          :title="$t('shipment.buttons.add_contact')"
          :class="{
            '!tw-text-blue-500 !hover:tw-text-blue-600': direction === 'pickup',
            '!tw-text-green-500 !hover:tw-text-green-600': direction === 'delivery'
          }"
          type="button"
          variant="link"
          class="shipment-detail-contact__add"
          data-test="add-button"
          @click="edit"
        >
          <template #left-icon>
            <UiMaterialIcon
              name="add_circle"
              data-test="icon"
            />
          </template>
          <div
            v-text="$t('shipment.buttons.add_contact')"
          />
        </UiButton>
      </div>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - ShipmentDetailContact
   * @param {string} direction
   * @param {object} contact
   * @param {object} shipment
   * @param {boolean} [hasEdit=true]
   */
  export default defineComponent({
    name: 'ShipmentDetailContact',
    props: {
      direction: {
        type: String,
        required: true
      },
      contact: {
        type: Object,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      },
      hasEdit: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      /**
       * Returns true if there is either a name or a phone number in the
       * contact informations
       * @function hasContactInformations
       */
      hasContactInformations () {
        return this.contact && (!!this.contact.name || !!this.contact.phone)
      }
    },
    methods: {
      edit () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', this.direction === 'pickup'
            ? 'Initiated Pickup Contact Edition'
            : 'Initiated Delivery Contact Edition')
        }

        EventBus.$emit('shipment:contact', {
          direction: this.direction,
          shipment: this.shipment
        })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-detail-contact {
    &__contact.pickup {
      border-left: 5px solid $info;
    }

    &__contact.delivery {
      border-left: 5px solid $primary;
    }

    &__add {
      &:focus {
        box-shadow: none;
      }
    }
  }

</style>
