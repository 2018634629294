<template>
  <div class="shipments-sidebar tw-w-full">
    <ctk-collapse-category-item
      :title="$options.filters.capitalize($t('navigation.shipments'))"
      :icon="'marker'"
      data-test="category"
    />

    <shipments-sidebar-item
      v-for="state in states"
      :key="state.state"
      :state="state.state"
      :has-dot="state.hasDot"
      :data-test="state.state"
      @click.native="closeSidebar"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkCollapseCategoryItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseCategoryItem/index.vue'
  import ShipmentsSidebarItem from './_subs/ShipmentsSidebarItem/index.vue'

  /**
   * @module component - shipmentsSidebar
   * @emits close
   */
  export default defineComponent({
    name: 'ShipmentsSidebar',
    components: {
      CtkCollapseCategoryItem,
      ShipmentsSidebarItem
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      states () {
        let counts
        if (this.getShipmentsMetrics) {
          counts = this.getShipmentsMetrics.counts
        }

        return [
          {
            state: 'awaiting_acceptation',
            hasDot: false
          },
          {
            state: 'available',
            hasDot: false
          },
          {
            state: 'planned',
            hasDot: counts && counts.requires_payment && counts.requires_payment.planned > 0
          },
          {
            state: 'in_progress',
            hasDot: counts && counts.requires_payment && counts.requires_payment.in_progress > 0
          },
          {
            state: 'completed',
            hasDot: false
          },
          {
            state: 'cancelled',
            hasDot: false
          },
          {
            state: 'refused',
            hasDot: false
          }
        ]
      }
    },
    methods: {
      /**
       * Close the sidebar on click if we're on a low size device
       * @function closeSidebar
       */
      closeSidebar () {
        const width = document.documentElement.clientWidth
        if (width <= 768) {
          this.$emit('close')
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipments-sidebar {
    padding: 30px 28px;

    .ctk-collapse-nav-item {
      padding-left: 30px;
    }
  }

</style>
