var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-item tw-rounded"},[_c('ctk-item',{staticClass:"tw-flex tw-flex-col shipment-item__link tw-rounded",class:{
      'shipment-item__link--need-payment': _vm.needPayment
    },attrs:{"to":{
      name: 'Shipment',
      params: {
        uuid: _vm.shipment.uuid
      }
    },"event":'',"touchable-type":"router-link"},nativeOn:{"click":function($event){return _vm.clicked.apply(null, arguments)}}},[_c('shipment-item-header',{attrs:{"shipment":_vm.shipment,"has-expiration":_vm.hasAvailableState && !_vm.needPayment,"has-infos":_vm.hasInfos}}),_c('shipment-item-content',{attrs:{"shipment":_vm.shipment,"has-options":_vm.hasAvailableState,"has-price":_vm.hasAvailableState || _vm.hasAwaitingAcceptationState,"has-tracking":_vm.allowedStatusWithTracking.includes(_vm.$route.params.state),"has-cancellation-dates":_vm.cancelledStatus.includes(_vm.$route.params.state) && !!_vm.shipment.cancellation,"has-rating":_vm.hasRating}})],1),(_vm.shipment.pending_proposal_count > 0 && _vm.$route.params.state === 'available' && !_vm.isCorridor)?_c('shipment-item-proposals',{attrs:{"shipment":_vm.shipment}}):_vm._e(),_c('shipment-item-footer',{attrs:{"shipment":_vm.shipment,"has-tracking":_vm.allowedStatusWithTracking.includes(_vm.$route.params.state),"has-cancellation-dates":_vm.cancelledStatus.includes(_vm.$route.params.state) && !!_vm.shipment.cancellation,"has-rating":_vm.hasRating}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }