var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex shipment-summary-tracking-item tw-relative",class:{
    active: _vm.active,
    'dash-active': _vm.dashActive,
    'dash-checked': _vm.dashChecked,
    'dash-cancelled': _vm.dashCancelled,
    'dash-muted': _vm.dashMuted,
    'is-waiting': _vm.isWaiting,
    cancelled: _vm.cancelled,
    muted: _vm.muted,
    checked: _vm.checked,
    shortned: !_vm.value
  }},[_c('div',{staticClass:"tw-flex flex-fixed shipment-summary-tracking-item__dot__container"},[_c('div',{staticClass:"tw-flex shipment-summary-tracking-item__dot tw-text-white tw-rounded-full tw-m-auto",class:{
        'has-check': _vm.checked,
        'is-waiting': _vm.isWaiting
      }},[(_vm.checked)?_c('ui-material-icon',{staticClass:"tw-relative tw-m-auto",attrs:{"name":_vm.UIIconName}}):_vm._e()],1)]),_c('div',{staticClass:"tw-flex-1 tw-flex tw-flex-col shipment-summary-tracking-item__content tw-truncate"},[_c('div',{staticClass:"shipment-summary-tracking-item__content__title tw-truncate tw-flex tw-items-center tw-text-gray-800"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.hasTooltip)?_c('ui-material-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right.hover",value:(_vm.tooltipOptions),expression:"tooltipOptions",modifiers:{"right":true,"hover":true}}],staticClass:"tw-text-sm tw-ml-2",attrs:{"name":"info"},nativeOn:{"click":function($event){return _vm.click.apply(null, arguments)}}}):_vm._e()],1),(_vm.value)?_c('div',{staticClass:"shipment-summary-tracking-item__content__value tw-text-gray-800 tw-text-sm tw-truncate"},[_vm._v(" "+_vm._s(_vm.value)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }