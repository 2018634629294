<template>
  <div class="shipment-detail-reference tw-flex tw-justify-end">
    <router-link
      :to="{
        name: 'Faq'
      }"
      class="shipment-detail-reference__faq-link"
      @click.native="$emit('close')"
      data-test="faq-link"
    >
      <ui-ctk-icon
        name="help"
        class="tw--mr-1"
        data-test="icon"
      />
      <span
        v-text="$options.filters.capitalize($t('navigation.faq'))"
        class="tw-hidden lg:tw-block"
        data-test="content"
      />
    </router-link>
    <div
      data-test="phone"
      class="shipment-detail-reference__phone tw-flex tw-items-center <lg:tw-flex-col tw-gap-x-2"
    >
      <div class="tw-text-right">
        <span
          v-text="$t('shipment.labels.contact')"
          data-test="label"
        />
        <span
          v-text="$options.filters.telephone(getCurrentShipment.shipper_phone_number)"
          class="tw-font-medium"
          data-test="value"
        />
      </div>
      <shipment-update-phone-link
        v-if="getCurrentShipment.state === 'available' && !$wait.is('fetching shipment')"
        :shipment="getCurrentShipment"
        class="tw-text-right"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentUpdatePhoneLink from '@/views/Shippers/Shipment/components/ShipmentUpdatePhoneLink'

  /**
   * @module component - ShipmentDetailReference
   */
  export default {
    name: 'ShipmentDetailReference',
    components: {
      ShipmentUpdatePhoneLink
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.shipment-detail-reference {
  background-color: white;
  padding: 8px 16px;
}
.shipment-detail-reference__phone .shipment-update-phone-link {
  margin-left: auto;
}
.shipment-detail-reference__faq-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: auto;
  margin-left: -8px;
  margin-bottom: 0.5rem;
  padding-left: 0px;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.shipment-detail-reference__faq-link:hover {
  text-decoration: none;
}
@media (min-width: 770px) {
  .shipment-detail-reference__faq-link {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: $breakpoint-laptop-s) {
  .shipment-detail-reference {
    padding: 8px 32px;
  }
}
</style>
